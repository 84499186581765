var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ExportNs } from '../../esl-utils/environment/export-ns';
import { ESLPopup } from '../../esl-popup/core';
import { memoize, attr, boolAttr, listen, prop } from '../../esl-utils/decorators';
import { TAB } from '../../esl-utils/dom/keys';
import { getKeyboardFocusableElements, handleFocusChain } from '../../esl-utils/dom/focus';
let ESLTooltip = class ESLTooltip extends ESLPopup {
    /** Shared instanse of Tooltip */
    static get sharedInstance() {
        return document.createElement('esl-tooltip');
    }
    /** List of all focusable elements inside instance */
    get $focusables() {
        return getKeyboardFocusableElements(this);
    }
    /** First and last focusable elements inside instance */
    get $boundaryFocusable() {
        const { $focusables } = this;
        const $first = $focusables[0];
        const $last = $focusables.pop();
        return { $first, $last };
    }
    /** Active state marker */
    static get open() {
        return this.sharedInstance.open;
    }
    /** Changes the element state to active */
    static show(params = {}) {
        this.sharedInstance.show(params);
    }
    /** Changes the element state to inactive */
    static hide(params = {}) {
        this.sharedInstance.hide(params);
    }
    connectedCallback() {
        super.connectedCallback();
        this.classList.add(ESLPopup.is);
        this.classList.toggle('disable-arrow', this.disableArrow);
        this.tabIndex = 0;
    }
    /** Sets initial state of the Tooltip */
    setInitialState() { }
    /** Actions to execute on show Tooltip. */
    onShow(params) {
        if (params.disableArrow) {
            this.disableArrow = params.disableArrow;
        }
        if (params.text) {
            this.innerText = params.text;
        }
        if (params.html) {
            this.innerHTML = params.html;
        }
        this.dir = params.dir || '';
        this.lang = params.lang || '';
        this.parentNode !== document.body && document.body.appendChild(this);
        super.onShow(params);
    }
    /** Actions to execute on Tooltip hiding. */
    onHide(params) {
        super.onHide(params);
        this.parentNode === document.body && document.body.removeChild(this);
    }
    _onKeyboardEvent(e) {
        super._onKeyboardEvent(e);
        if (e.key === TAB)
            this._onTabKey(e);
    }
    /** Actions on TAB keypressed */
    _onTabKey(e) {
        if (!this.activator)
            return;
        const { $first, $last } = this.$boundaryFocusable;
        if (this.hasFocusLoop)
            return handleFocusChain(e, $first, $last);
        if (!$last || e.target === (e.shiftKey ? $first : $last)) {
            this.activator.focus();
            e.preventDefault();
        }
    }
};
ESLTooltip.is = 'esl-tooltip';
/** Default params to pass into the tooltip on show/hide actions */
ESLTooltip.DEFAULT_PARAMS = Object.assign(Object.assign({}, ESLPopup.DEFAULT_PARAMS), { autofocus: true });
__decorate([
    prop(false)
], ESLTooltip.prototype, "hasFocusLoop", void 0);
__decorate([
    attr({ defaultValue: 'top' })
], ESLTooltip.prototype, "position", void 0);
__decorate([
    attr({ defaultValue: 'fit' })
], ESLTooltip.prototype, "behavior", void 0);
__decorate([
    boolAttr()
], ESLTooltip.prototype, "disableArrow", void 0);
__decorate([
    listen({ inherit: true })
], ESLTooltip.prototype, "_onKeyboardEvent", null);
__decorate([
    memoize()
], ESLTooltip, "sharedInstance", null);
ESLTooltip = __decorate([
    ExportNs('Tooltip')
], ESLTooltip);
export { ESLTooltip };
