var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ESLNote_1;
import { ExportNs } from '../../esl-utils/environment/export-ns';
import { ESLBaseElement } from '../../esl-base-element/core';
import { ready, attr, prop, boolAttr, memoize, listen } from '../../esl-utils/decorators';
import { ESLTooltip } from '../../esl-tooltip/core';
import { promisifyTimeout, repeatSequence } from '../../esl-utils/async';
import { ESLEventUtils } from '../../esl-utils/dom/events';
import { ENTER, SPACE } from '../../esl-utils/dom/keys';
import { scrollIntoView } from '../../esl-utils/dom/scroll';
import { hasHover } from '../../esl-utils/environment/device-detector';
import { ESLMediaQuery } from '../../esl-media-query/core';
import { ESLTraversingQuery } from '../../esl-traversing-query/core';
let ESLNote = ESLNote_1 = class ESLNote extends ESLBaseElement {
    /** Marker to allow track hover */
    get allowHover() {
        return hasHover && ESLMediaQuery.for(this.trackHover).matches;
    }
    /** Marker to allow track clicks */
    get allowClick() {
        return ESLMediaQuery.for(this.trackClick).matches;
    }
    /** Marker to allow footnotes to pick up this note */
    get allowFootnotes() {
        return !this.queryToIgnore.matches;
    }
    /** Note index in the scope content */
    get index() {
        return this._index;
    }
    set index(value) {
        this._index = value;
        this.innerHTML = this.renderedHTML;
    }
    /** Note index in the displayed list of footnotes */
    get renderedIndex() {
        return this.allowFootnotes ? `${this._index}` : this.standaloneLabel;
    }
    /** Note markup */
    get renderedHTML() {
        const index = this.renderedIndex;
        if (!this._$footnotes)
            return index;
        const footnotesIndexId = `${this._$footnotes.id}-${index}`;
        return `<a class="esl-note-link" href="#${footnotesIndexId}" tabindex="-1">${index}</a>`;
    }
    /** Query to describe conditions to ignore note by footnotes  */
    get queryToIgnore() {
        const ignore = this.getClosestRelatedAttr('ignore') || this.ignore;
        return ESLMediaQuery.for(ignore);
    }
    /** The text writing directionality of the element */
    get currentDir() {
        return getComputedStyle(this).direction;
    }
    /** The base language of the element */
    get currentLang() {
        const el = this.closest('[lang]');
        return (el) ? el.lang : '';
    }
    /** @returns true if tooltip is active and activated with the current note */
    get isTargetActive() {
        const $target = ESLTooltip.sharedInstance;
        return $target && $target.open && $target.activator === this;
    }
    connectedCallback() {
        this.init();
        super.connectedCallback();
        this._sendResponseToFootnote();
    }
    disconnectedCallback() {
        var _a;
        super.disconnectedCallback();
        (_a = this._$footnotes) === null || _a === void 0 ? void 0 : _a.unlinkNote(this);
        this.restore();
    }
    attributeChangedCallback(attrName, oldVal, newVal) {
        if (!this.connected || oldVal === newVal)
            return;
        if (attrName === 'ignore') {
            this.updateIgnoredQuery();
        }
    }
    /** Revises the settings for ignoring the note */
    updateIgnoredQuery() {
        memoize.clear(this, 'queryToIgnore');
        this.$$on(this._onIgnoreConditionChange);
        this._onIgnoreConditionChange();
    }
    /** Gets attribute value from the closest element with group behavior settings */
    getClosestRelatedAttr(attrName) {
        const relatedAttrName = `${this.baseTagName}-${attrName}`;
        const $closest = this.closest(`[${relatedAttrName}]`);
        return $closest ? $closest.getAttribute(relatedAttrName) : null;
    }
    /** Activates note */
    activate() {
        if (ESLTooltip.open) {
            this.hideTooltip();
        }
        ESLEventUtils.dispatch(this, 'esl:show:request');
        // TODO: replace timeout with a more reliable mechanism to have time to show content with this note
        repeatSequence(() => {
            return promisifyTimeout(this.constructor.activateTimeout)
                .then(() => scrollIntoView(this, { behavior: 'smooth', block: 'center' }))
                .then(() => ESLTooltip.open || this.showTooltip());
        }, 3);
    }
    /** Highlights note */
    highlight(enable = true) {
        this.classList.toggle('highlight', enable);
    }
    /** Links note with footnotes */
    link(footnotes, index) {
        this.linked = true;
        this._$footnotes = footnotes;
        this.index = index;
        this.tabIndex = 0;
        this.update();
    }
    /** Unlinks note from footnotes */
    unlink() {
        this.restore();
        this.update();
        this._sendResponseToFootnote();
    }
    /** Updates note state */
    update() {
        this.standalone = !(this.linked && this.allowFootnotes);
    }
    /** Initial initialization of the element during the connection to DOM */
    init() {
        if (!this.html) {
            this.html = this.innerHTML;
        }
        memoize.clear(this, 'queryToIgnore');
        this.index = 0;
        this.linked = false;
        this.update();
    }
    /** Restores original note content after unlinking */
    restore() {
        this.linked = false;
        this._$footnotes = null;
        this.index = 0;
        this.tabIndex = -1;
    }
    /** Merge params to pass to the toggleable */
    mergeToggleableParams(...params) {
        const container = this.getClosestRelatedAttr('container') || this.container;
        const containerEl = container ? ESLTraversingQuery.first(container, this) : undefined;
        return Object.assign({
            initiator: 'note',
            activator: this,
            containerEl,
            html: this.html,
            dir: this.currentDir,
            lang: this.currentLang,
            intersectionMargin: this.intersectionMargin
        }, ...params);
    }
    /** Shows tooltip with passed params */
    showTooltip(params = {}) {
        const actionParams = this.mergeToggleableParams({}, params);
        if (ESLTooltip.open) {
            this.hideTooltip();
        }
        ESLTooltip.show(actionParams);
        this.highlight();
    }
    /** Hides tooltip with passed params */
    hideTooltip(params = {}) {
        const actionParams = this.mergeToggleableParams({}, params);
        ESLTooltip.hide(actionParams);
    }
    /** Toggles tooltip with passed params */
    toggleTooltip(params = {}, state = !this.tooltipShown) {
        state ? this.showTooltip(params) : this.hideTooltip(params);
    }
    /** Handles `click` event */
    _onClick(event) {
        if (!this.allowClick)
            return;
        event.preventDefault();
        event.stopPropagation();
        return this.toggleTooltip({ event });
    }
    /** Handles `keydown` event */
    _onKeydown(event) {
        if (![ENTER, SPACE].includes(event.key))
            return;
        event.preventDefault();
        event.stopPropagation();
        return this.toggleTooltip({ event });
    }
    /** Handles hover `mouseenter` event */
    _onMouseEnter(event) {
        if (!this.allowHover)
            return;
        this.showTooltip({ event });
        event.preventDefault();
    }
    /** Handles hover `mouseleave` event */
    _onMouseLeave(event) {
        if (!this.allowHover)
            return;
        this.hideTooltip({ event, trackHover: true });
        event.preventDefault();
    }
    /** Actions on breakpoint changing */
    _onIgnoreConditionChange() {
        var _a;
        if (ESLTooltip.open) {
            this.hideTooltip();
        }
        this.innerHTML = this.renderedHTML;
        this.update();
        (_a = this._$footnotes) === null || _a === void 0 ? void 0 : _a.update();
    }
    /** Handles footnotes request event */
    _onFootnotesReady(e) {
        if (this.linked)
            return;
        this._sendResponseToFootnote();
    }
    /** Handles ESLToggleable state change */
    _onTargetStateChange(event) {
        var _a;
        this.tooltipShown = ESLTooltip.open && this.isTargetActive;
        if (!this.tooltipShown)
            (_a = this._$footnotes) === null || _a === void 0 ? void 0 : _a.turnOffHighlight(this);
    }
    /** Sends the response to footnotes */
    _sendResponseToFootnote() {
        ESLEventUtils.dispatch(this, this.FOOTNOTE_RESPONSE_EVENT);
    }
};
ESLNote.is = 'esl-note';
ESLNote.observedAttributes = ['ignore'];
/** Timeout before activating note (to have time to show content with this note) */
ESLNote.activateTimeout = 100;
__decorate([
    prop('esl:footnotes:request')
], ESLNote.prototype, "FOOTNOTE_REQUEST_EVENT", void 0);
__decorate([
    prop('esl:footnotes:response')
], ESLNote.prototype, "FOOTNOTE_RESPONSE_EVENT", void 0);
__decorate([
    boolAttr()
], ESLNote.prototype, "linked", void 0);
__decorate([
    boolAttr()
], ESLNote.prototype, "standalone", void 0);
__decorate([
    boolAttr()
], ESLNote.prototype, "tooltipShown", void 0);
__decorate([
    attr({ defaultValue: 'not all' })
], ESLNote.prototype, "ignore", void 0);
__decorate([
    attr()
], ESLNote.prototype, "html", void 0);
__decorate([
    attr({ defaultValue: '*' })
], ESLNote.prototype, "standaloneLabel", void 0);
__decorate([
    attr({ defaultValue: 'all' })
], ESLNote.prototype, "trackClick", void 0);
__decorate([
    attr({ defaultValue: 'all' })
], ESLNote.prototype, "trackHover", void 0);
__decorate([
    attr()
], ESLNote.prototype, "container", void 0);
__decorate([
    attr({ defaultValue: '0px' })
], ESLNote.prototype, "intersectionMargin", void 0);
__decorate([
    memoize()
], ESLNote.prototype, "queryToIgnore", null);
__decorate([
    ready
], ESLNote.prototype, "connectedCallback", null);
__decorate([
    ready
], ESLNote.prototype, "disconnectedCallback", null);
__decorate([
    listen('click')
], ESLNote.prototype, "_onClick", null);
__decorate([
    listen('keydown')
], ESLNote.prototype, "_onKeydown", null);
__decorate([
    listen('mouseenter')
], ESLNote.prototype, "_onMouseEnter", null);
__decorate([
    listen('mouseleave')
], ESLNote.prototype, "_onMouseLeave", null);
__decorate([
    listen({
        event: 'change',
        target: (el) => el.queryToIgnore
    })
], ESLNote.prototype, "_onIgnoreConditionChange", null);
__decorate([
    listen({
        event: (el) => el.FOOTNOTE_REQUEST_EVENT,
        target: document.body
    })
], ESLNote.prototype, "_onFootnotesReady", null);
__decorate([
    listen({
        event: 'esl:show esl:hide',
        target: () => ESLTooltip.sharedInstance
    })
], ESLNote.prototype, "_onTargetStateChange", null);
ESLNote = ESLNote_1 = __decorate([
    ExportNs('Note')
], ESLNote);
export { ESLNote };
