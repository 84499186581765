var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ESLCarouselRenderer } from '../core/esl-carousel.renderer';
/**
 * None effect carousel renderer. Does not provide any animation, transition. Does not limit slide stage.
 * All slides considered as active. Count properly is ignored (always equal to the total slide count).
 */
let ESLNoneCarouselRenderer = class ESLNoneCarouselRenderer extends ESLCarouselRenderer {
    constructor($carousel, options) {
        super($carousel, options);
        // Note blocks touch plugin from activating (consider rework if scroll behaviour is requested)
        Object.defineProperty(this, 'count', { get: () => this.size });
    }
    /**
     * Processes binding of defined renderer to the carousel {@link ESLCarousel}.
     * Prepare to renderer animation.
     */
    onBind() {
        this.setActive(0);
    }
    onUnbind() { }
    /** Processes animation. */
    onAnimate(nextIndex, direction) {
        return __awaiter(this, void 0, void 0, function* () { });
    }
    /* Handles the slide move actions */
    move(offset, from, params) { }
    commit(offset, from, params) {
        return __awaiter(this, void 0, void 0, function* () { });
    }
};
ESLNoneCarouselRenderer.is = 'none';
ESLNoneCarouselRenderer.classes = ['esl-carousel-none-renderer'];
ESLNoneCarouselRenderer = __decorate([
    ESLCarouselRenderer.register
], ESLNoneCarouselRenderer);
export { ESLNoneCarouselRenderer };
